import React, { useEffect, useRef } from 'react';
import 'antd/dist/antd.css';
import { Layout, Menu, Breadcrumb } from 'antd';
import PageLayout from '../components/Layout';
import FullpageWrapper from '../components/HomeCarosle';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import Meta from '../components/Meta';

const { Content } = Layout;

export default (props) => {
  
  return (
  <div style={{margin: "0px"}}>
    <Meta location={props.location} />
    {/* <Header selectedKey="" /> */}
    <FullpageWrapper />
  </div>
)};