import React from "react"
import mulesoftLogo from '../../images/mulesoft-logo-1.png';
import salesforceLogo from '../../images/salesforce-logo.svg';
import { Row, Card, Col, Button } from "antd"

const services = [
    {
        id: 1,
        title: "Salesforce Marketing Cloud",
        image: salesforceLogo,
        description: "Our Salesforce Marketing Cloud expertise, you can reach your customers at the right time in the right way. If you need to reach your individual customers in a more personalized way, Salesforce Marketing Cloud is the answer for you."
    },
    {
        id: 2,
        title: "Pardot",
        image: salesforceLogo,
        description: "Success of any business ultimately boils down to its customers. A business needs a depth analysis of prospects to customers conversion. Pardot is a marketing automation platform provided by Salesforce to increase your marketing efforts, campaign effectiveness, and much more."
    },
    {
        id: 3,
        title: "Salesforce Experience Cloud",
        image: salesforceLogo,
        description: "Built on the Salesforce platform, Experience Cloud is a digital platform that helps companies build connected CRM-powered digital experiences. Experience Cloud enables companies to build content in one place and deliver it anywhere; websites, portals, mobile apps and storefronts."
    },
    {
        id: 4,
        title: "Mulesoft",
        image: mulesoftLogo,
        description: "MuleSoft — the world's #1 integration and API management platform — has helped thousands of customers automate, integrate, and execute faster than ever before."
    },
    {
        id: 5,
        title: "Salesforce Sales Cloud",
        image: salesforceLogo,
        description: "Salesforce Sales Cloud is a customer relationship management (CRM) platform designed to support sales, marketing and customer support in both business-to-business (B2B) and business-to-customer (B2C) contexts."
    },
    {
        id: 6,
        title: "Salesforce Service Cloud",
        image: salesforceLogo,
        description: "Salesforce Service Cloud is a customer relationship management (CRM) platform for customer service and support. Salesforce based Service Cloud on its Sales Cloud product, a popular CRM software for sales professionals."
    }
]

const ServicesCard = (props) => {

    const showAwards = (item: any) => {
        return (
            <Col lg={10} md={10} sm={24}>
                <Card className="m-1" style={{height: "96%", borderRadius: "0.625rem", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.16)"}} key={item.id}>
                    <Row type="flex" align="middle" justify="center">
                        <Col sm={20} xs={20} span={6} className="p-md-1 text-align-center">
                            <img src={item.image} width={100} className="mw-80"/>
                        </Col>
                        <Col sm={20} xs={20} span={18} align="center">
                            <h2 className="font-large fw-600 pb-1">{item.title}</h2>
                            <p className="mb-0" style={{lineHeight: "1.6", opacity: "0.8"}}>{item.description}</p>
                        </Col>
                    </Row>
                </Card>
            </Col>
        )
    }

    const showMoreAwards = () => {
        const showAwards = document.getElementById("showMore")
        if (showAwards && showAwards.style !== null)
            showAwards.style.display = "block"
    }

    return (
        <div className="pt-5 mt-5 pb-5">
            <h1 className="heading-3 text-align-center">Services we deliver</h1>
            <Row className="font-large pb-md-9 px-5 text-align-center" style={{lineHeight: "1.7"}} type="flex" justify="center"><p>We will be your implementation parterns for these amazing SAAS products from Salesforce</p></Row>
            <Row className="pt-2 px-md-8 px-2" type="flex" justify="center">
                {services.map((item) => showAwards(item))}
            </Row>
            
        </div>
    )
}

export default ServicesCard