import React from "react"
import ReactFullpage, { Slide } from "@fullpage/react-fullpage"
// import "../styles/app.less"
import "./styles.css";
import upArrowIcon from "../../images/arrow-up.png"
import downArrowIcon from "../../images/arrow-down.png"
import ServicesCard from "../ServicesCard"
import { Section1, Section2, Section3 } from "../HomeCarosle/CoverSection"
import Footer from "../Footer"
import PageHeader from "../Header";
const SEL = "custom-section"
const SECTION_SEL = `.${SEL}`
//"@fullpage/react-fullpage": "^0.1.25",
class App extends React.Component<any> {
  intervalId
  constructor(props) {
    super(props)
    this.intervalId = null
    this.state = {
      fullpageApiObject: null,
      isTransparentHeader: false
    }
  }

  componentDidMount() {
    let visited = sessionStorage["alreadyVisited"]

    if (visited && sessionStorage.getItem("clicked")) {
      this.setState({ disclaimerVisibility: false })
    } else {
      sessionStorage["alreadyVisited"] = true
      this.setState({ disclaimerVisibility: true })
    }

    let self = this
    let navDots = document.querySelector("#fp-nav")
    let downBtn = document.createElement("img")
    let upBtn = document.createElement("img")
    let body = document.getElementById("___gatsby")
    upBtn.src = upArrowIcon
    upBtn.setAttribute("class", "nav-arrow")
    downBtn.setAttribute("class", "nav-arrow")
    downBtn.src = downArrowIcon
    downBtn.addEventListener("click", function() {
      window.fullpage_api.moveSectionDown()
    })
    upBtn.addEventListener("click", function() {
      window.fullpage_api.moveSectionUp()
    })
    downBtn.innerHTML = "Down"
    upBtn.innerHTML = "Up"
    navDots.appendChild(downBtn)
    navDots.insertAdjacentElement("afterbegin", upBtn)
    window.onresize = function() {
      window.clearInterval(self.intervalId)
      if (window.innerWidth > 600)
        self.intervalId = setInterval(self.autoScrollPage, 10000)
    }
    if (window.innerWidth > 600)
      self.intervalId = setInterval(self.autoScrollPage, 10000)
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalId)
  }


  autoScrollPage = () => {
    const { fullpageApiObject } = this.state
    if (!fullpageApiObject) {
      return
    }
    const currentSection = fullpageApiObject.getActiveSection().index
    if (currentSection !== 3) {
      if (currentSection === 0) {
        fullpageApiObject.moveTo(2)
      }
      if (currentSection === 1) {
        fullpageApiObject.moveTo(3)
      }
      if (currentSection === 2) {
        fullpageApiObject.moveTo(1)
      }
    }
  }

  setFullPageObject = fullpageApiObject => {
    this.setState({ fullpageApiObject })
  }

  changeDisclaimerView = (value: boolean) => {
    this.setState({ setDisclaimerVisibility: value })
  }

  handleDisclaimerButton = (value: boolean) => {
    sessionStorage.setItem("clicked", value)
    this.setState({ buttonVisibility: value })
  }

  onLeave = (origin, destination, direction) => {
    console.log(destination);
    destination.index == 2 ? this.setState({isTransparentHeader: false}) : this.setState({isTransparentHeader: false});
  }

  render() {
    const {
      fullpageApiObject,
      isTransparentHeader
    } = this.state
    return (
      <>
        <PageHeader selectedKey="" isTransparentHeader={isTransparentHeader} />
        <div className="overflowX-hidden">
            <ReactFullpage
              anchors={["welcome", "marketing-cloud", "services"]}
              navigation={true}
              arrowNavigation={true}
              lazyLoading={true}
              responsiveWidth={480}
              autoScrolling={true}
              fitToSectionDelay={1000}
              fitToSection={false}
              sectionSelector={SECTION_SEL}
              scrollOverflow={true}
              controlArrows={false}
              onLeave={this.onLeave}
              render={({ state, fullpageApi }) => {
                if (!fullpageApiObject) {
                  this.setFullPageObject(fullpageApi)
                }
                return (
                  <>
                    <div
                      className={`custom-section fp-auto-height min-vh-100 fp-noscroll`}
                    >
                      <Section1 />
                    </div>
                    <div className="custom-section fp-auto-height min-vh-100 fp-noscroll">
                      <Section2 />
                    </div>
                    {/* <div className="custom-section fp-auto-height min-vh-100 fp-noscroll">
                      <Section3 />
                    </div> */}
                    <div className="custom-section fp-auto-height-responsive">
                      <ServicesCard />
                      <Footer />
                    </div>
                  </>
                )
              }}
            />
          
        </div>
      </>
    )
  }
}

export default App
